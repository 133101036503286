<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="subtitle-1 text--secondary font-weight-medium">
            <router-link :to="{name: 'AccountExports'}" class="subtitle-1 routerLink font-weight-medium">Exportar</router-link>
            <v-icon class="mx-1" color="secondary">mdi-chevron-right</v-icon>Clientes
          </v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 pb-0">
          <v-row class="px-4">
            <v-col cols="12" sm="10" md="9">
              <span class="body-2 text--secondary font-weight-medium">Filtrar registros</span>
              <v-query-builder :flat="true" :query-map="filters" filter-text="Previsualizar" model="customer" />
            </v-col>
          </v-row>
          <v-row class="px-4">
            <v-col>
              <span class="body-2 text--secondary font-weight-medium">Previsualización</span>
            </v-col>
          </v-row>
          <v-divider />
          <customer-list :show-pagination="false" />
          <v-divider />
          <v-row class="px-4">
            <v-col>
              <span class="body-2 text--secondary font-weight-medium">{{count}} registros</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="createExport" :loading="$store.state.accounts.createLoader" color="primary">Exportar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import CustomerList from '@/modules/customer/components/CustomerList'
import ListViewMixin from '@/mixins/ListViewMixin'
export default {
  components: {
    CustomerList,
    VQueryBuilder
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    isPreview: false,
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'tax_id',
        label: 'Número fiscal',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'created',
        label: 'Fecha de creación',
        operators: ['es igual', 'es menor a', 'es mayor a']
      },
      {
        type: 'radio',
        id: 'sources',
        label: 'Método de pago',
        choices: [{label: 'Si', value: 'True'}, {label: 'No', value: 'False'}]
      },
      {
        type: 'text',
        id: 'email',
        label: 'Email',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'country__name',
        label: 'País',
        operators: ['contiene']
      }
    ]
  }),
  computed: mapState({
    count: state => state.customers.customersCount,
    customersList: state => state.customers.customersList
  }),
  methods: {
    getList () {
      this.$store.dispatch('customers/LIST', {
        resource: 'customers',
        query: Object.assign({}, this.$route.query, {
          page_size: 10
        })
      })
    },
    str2bytes (str) {
      var bytes = new Uint8Array(str.length)
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i)
      }
      return bytes
    },
    createExport () {
      this.$store.dispatch('accounts/LIST', {
        resource: 'exports/customers',
        query: this.$route.query,
        loader: true,
        responseType: 'arraybuffer'
      })
      .then(async (response) => {
        let blob = await new Blob([response.data], {type: "application/zip"})
        saveAs(blob, 'customers.zip')
        this.$dialog.message.info('La exportación se realizó con éxito.')
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error en la exportación.')
      })
    }
  }
}
</script>